import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
// import { urlSafeString } from "../helpers/urlSafeString";

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { languageMerge } from "../helpers/languageMerge";
import { useTranslation } from "react-i18next";
import ScrollScaleHero from "../components/ScrollScaleHero";
import Link from "../helpers/Link";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;

  & img {
    max-width: 100%;
  }
`;

const GalleryGrid = styled.div`
  margin-top: 10vh;
  margin-bottom: 20vh;
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
  }

  & a {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.6;
    }
  }

  width: 80vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -40vw !important;
  margin-right: -40vw !important;
`;

const GridSquareImage = styled.div`
  &:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
  }

  background: url(${({ image }) => image}) no-repeat center center;
  background-size: cover;
`;

const Hero = styled.div`
  height: 100vh;
  overflow: hidden;
  /* background: rgba(0, 0, 0, 0.1); */
  display: grid !important;
  width: 100%;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &:before {
    content: "";
    position: absolute;
    /* scroll-snap-align: center; */
  }

  &:after {
    box-shadow: inset 0px 200px 180px -180px rgb(0 0 0 / 25%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
  }
`;

const ContentWrap = styled.div`
  padding: 0 10vw;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: content-box;
  color: #323231;
  @media print {
    & *,
    & *:before,
    & *:after {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    & a,
    & a:visited {
      text-decoration: underline;
    }

    & a[href]:after {
      content: " (" attr(href) ")";
    }

    & abbr[title]:after {
      content: " (" attr(title) ")";
    }

    & a[href^="#"]:after,
    & a[href^="javascript:"]:after {
      content: "";
    }

    & pre,
    & blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    & thead {
      display: table-header-group;
    }

    & tr,
    & img {
      page-break-inside: avoid;
    }

    & img {
      max-width: 100% !important;
    }

    & p,
    & h2,
    & h3 {
      orphans: 3;
      widows: 3;
    }

    & h2,
    & h3 {
      page-break-after: avoid;
    }
  }

  & pre,
  & code {
    font-family: Menlo, Monaco, "Courier New", monospace;
  }

  & pre {
    padding: 0.5rem;
    line-height: 1.25;
    overflow-x: scroll;
  }

  & a,
  & a:visited {
    color: #9fa85d;
    cursor: pointer;
    transition: color 0.3s ease;
    /* color: #3498db; */
  }

  & a:hover,
  & a:focus,
  & a:active {
    color: #bec876;
    text-decoration: underline;
    /* color: #2980b9; */
  }

  & .modest-no-decoration {
    text-decoration: none;
  }

  & html {
    font-size: 12px;
  }

  @media screen and (min-width: 32rem) and (max-width: 48rem) {
    & html {
      font-size: 15px;
    }
  }

  @media screen and (min-width: 48rem) {
    & html {
      font-size: 16px;
    }
  }

  & body {
    line-height: 1.85;
  }

  & p,
  & .modest-p {
    font-size: 1rem;
    margin-bottom: 1.3rem;
  }

  & h1,
  & .modest-h1,
  & h2,
  & .modest-h2,
  & h3,
  & .modest-h3,
  & h4,
  & .modest-h4 {
    margin: 1.414rem 0 0.5rem;
    font-weight: inherit;
    line-height: 1.42;
  }

  & h1,
  & .modest-h1 {
    margin-top: 0;
    font-size: 3.998rem;
  }

  & h2,
  & .modest-h2 {
    font-size: 2.827rem;
  }

  & h3,
  & .modest-h3 {
    font-size: 1.999rem;
  }

  & h4,
  & .modest-h4 {
    font-size: 1.414rem;
  }

  & h5,
  & .modest-h5 {
    font-size: 1.121rem;
  }

  & h6,
  & .modest-h6 {
    font-size: 0.88rem;
  }

  & small,
  & .modest-small {
    font-size: 0.707em;
  }

  /* https://github.com/mrmrs/fluidity */

  & img,
  & canvas,
  & iframe,
  & video,
  & svg,
  & select,
  & textarea {
    max-width: 100%;
  }

  & html {
    font-size: 18px;
    max-width: 100%;
  }

  & body {
    color: #444;
    font-weight: 300;
    margin: 0 auto;
    max-width: 48rem;
    line-height: 1.45;
    padding: 0.25rem;
  }

  /* & h1,
& h2,
& h3,
& h4,
& h5,
& h6 {
  font-family: Arimo, Helvetica, sans-serif;
} */

  & h1,
  & h2,
  & h3 {
    /* border-bottom: 2px solid #fafafa; */
    margin-bottom: 1.15rem;
    padding-bottom: 0.5rem;
    /* text-align: center; */
  }

  & blockquote {
    border-left: 8px solid #fafafa;
    padding: 1rem;
  }

  & pre,
  & code {
    background-color: #fafafa;
  }

  & .gatsby-resp-image-wrapper {
    width: 80vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin: 10vh -40vw 10vh -40vw !important;
  }

  & .location {
    font-size: 2rem;
    font-weight: 400;
  }

  & .slug {
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 0;
  }

  & .title {
    font-size: 2rem;
    font-weight: 800;
    margin: 0;
  }

  & .date {
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 0;
  }

  & .status {
    margin-left: 1em;
  }
`;

const Tag = styled.div`
  background: #525444;
  color: #fff;
  padding: 0.4em 1.3em 0.3em 1.3em;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
`;

const ProjectPage = ({ data, content, pageContext, preview = false }) => {
  const { i18n } = useTranslation();
  data = languageMerge(data, pageContext.language || i18n.language);
  data = data.allMarkdownRemark.edges[0].node;
  data = { ...data, ...data.frontmatter, frontmatter: null };
  const Wrap = !preview ? Layout : Wrapper;

  // return <pre>{JSON.stringify(data, 1, 1)}</pre>;

  const monthsSK = ["Jan", "Feb", "Mar", "Apr", "Máj", "Jún", "Júl", "Aug", "Sep", "Okt", "Nov", "Dec"];

  const date = new Date(data.date);
  const year = date.getFullYear();
  const month = monthsSK[date.getMonth()];

  return (
    <Wrap>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <title>{data.slug} | MDA</title>
      </Helmet>
      <SimpleReactLightbox>
        <ScrollScaleHero>
          <Hero>
            <GatsbyImage
              image={getImage(data.featuredimage)}
              alt={""}
              loading="eager"
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </Hero>
        </ScrollScaleHero>
        <ContentWrap>
          <br />
          <h2 className="slug">{data.slug}</h2>
          <h1 className="title">
            {data.title}, <span className="location">{data.location}</span>
          </h1>
          <h3 className="date">
            {month} {year},<span className="status">{data?.status || null}</span>
          </h3>

          <h2 className="date">
            {(data.tags || []).map(({ label, value }, i) => (
              <Link key={i} to={`/typologia/${value}`}>
                <Tag>{label}</Tag>
              </Link>
            ))}
          </h2>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.html }} />
          <GalleryGrid>
            <SRLWrapper
              options={{
                buttons: {
                  backgroundColor: "rgba(30,30,36,0.8)",
                  iconColor: "rgba(255, 255, 255, 0.8)",
                  iconPadding: "10px",
                  showAutoplayButton: false,
                  showCloseButton: true,
                  showDownloadButton: false,
                  showFullscreenButton: true,
                  showNextButton: true,
                  showPrevButton: true,
                  showThumbnailsButton: false,
                  size: "40px",
                },
              }}
            >
              {(data.galleryImages || []).map((image, i) => {
                const imageAttr = getImage(image);
                return (
                  <a href={image.url} key={i}>
                    {imageAttr ? (
                      <GatsbyImage image={imageAttr} alt={""} />
                    ) : (
                      <GridSquareImage
                        image={image.url}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </a>
                );
              })}
            </SRLWrapper>
          </GalleryGrid>
          {/* <details>
            <summary>Project data</summary>
            <pre>
              {JSON.stringify(
                {
                  ...data,
                  // featuredimage: null, galleryImages: null
                },
                1,
                1
              )}
            </pre>
          </details> */}
        </ContentWrap>
      </SimpleReactLightbox>
    </Wrap>
  );
};

export default ProjectPage;

export const pageQuery = graphql`
  query ProjectPageTemplate($ids: [String]!, $language: String!) {
    allMarkdownRemark(filter: { id: { in: $ids } }) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            status
            location
            date
            tags
            slug
            featuredimage {
              base
              url: publicURL
              childImageSharp {
                gatsbyImageData(width: 2048)
              }
            }
            galleryImages {
              base: base
              url: publicURL
              childImageSharp {
                gatsbyImageData(width: 640, height: 640)
              }
            }
          }
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
